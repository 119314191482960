span.question-placeholder {
  background: antiquewhite;
  padding: 0.2em 0.6em;
  border-radius: 1em;
  color: crimson;
}

.text-field-question {
  input {
    outline: none;
    width: 60px;
    padding: 0.4em 0.8em;
    border: none;
    border-bottom: 1px solid lightgray;

    &:focus {
      background: #fbf6e9;
      border-color: brown;
      border-radius: 1em;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  &.has-errors {
    input {
      background: mistyrose;
      color: darkred;
      border-color: red;
      &:disabled {
        background: #eee;
      }
    }
  }

  &.checking {
    input {
      background: rgba(255, 166, 0, 0.199);
      color: #aaa;
      border-color: #aaa;
    }
  }
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 2em;
}

.parsed-html {
  div.error {
    font-size: 1.2em;
    color: red;
    font-weight: 600;
  }
}
