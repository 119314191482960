.text-field-question {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  .question-title {
    display: flex;
    align-items: center;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input:disabled {
    cursor: not-allowed;
  }

  .checking-value {
    position: absolute;
    right: 0;
    color: rgba(0, 0, 0, 0.3);
  }
}
