@import "../../../../../variables.scss";
#root {
  .course-main-container {
    margin-top: 20px;

    .vertical {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2em;
      h4 {
        margin-top: 0em;
        color: $logoColor;
      }
    }

    .bottom-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 2em 0 2em;

      a[role="button"] {
        padding: 0.6em 1.2em;
        font-size: 1.15em;
        min-width: 10em;
        margin: 0 0.5em;
      }
    }

    .tester-tools {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      background: #00c0da2e;
      border-radius: 10px;
      &::before {
        position: absolute;
        content: "Testing tools";
        left: 0;
        top: -1.2em;
        font-size: 0.8em;
        color: #97d6e4;
        font-weight: 700;
      }

      button,
      a {
        margin: 0.4em 0.4em 0.4em 0;
      }
    }

    .parsed-html {
      img {
        max-width: 100%;
      }
    }
  }
}
