@import "../../../variables.scss";

#root {
  .course-tabs {
    margin-top: 1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .course-main-container {
    .course-accordion-block {
      position: relative;

      &.scrolled {
        .exp-list-container {
          // height: calc(100vh - $headerHeight);
          position: absolute;
          width: 100%;
        }
      }
    }
  }
  .exp-list-container {
    //the accordion always in viewport
    // position: sticky;
    position: absolute;
    width: 100%;
    top: 0;

    // just looks nice in most cases.
    // Have to switch to flex grow to bottom of the page, both this and experiment content
    // max-height: 52vh;
    // height: calc(100vh - $headerHeight);
    overflow-y: auto;
    overflow-x: hidden;

    counter-reset: exp-counter;
    .accordion-list {
      flex-grow: 1;

      .accordion-experiment-title {
        padding-left: 2em;
        display: flex;
        align-items: center;
        color: inherit;
        text-decoration: none;
        &::before {
          counter-increment: exp-counter;
          content: counter(exp-counter);
          background: gray;
          border-radius: 50%;
          width: 2em;
          height: 2em;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0.5em;
          color: white;
        }
      }
      .chapters {
        // make the accordion occupy the whole widths
        flex-grow: 1;
        a {
          &.active {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }

      // ul {
      //   li {
      //     display: flex;
      //     flex-grow: 1;
      //     a {
      //       text-decoration: none;
      //       color: inherit;
      //       display: flex;
      //       align-items: center;
      //       text-transform: capitalize;
      //       &:hover,
      //       &.active {
      //         color: black;
      //         background: gray;
      //       }
      //     }
      //   }
      // }
    }
  }
  .exp-content-container {
    //background-color: rgb(231, 231, 220);
    padding-left: 2em;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $primary;
    }

    h4 {
      margin: 2em 0 0;
    }
  }

  .error-message {
    text-align: center;
    padding: 60px;
  }
}
