@import "../../../variables.scss";

.modal-container {
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  // -6em is compensation for something - I guess paddings or whatever... It isn't in center ((
  // transform: translate(-50%, calc(-50% - 6em));
  transform: translate(-50%, -50% );
  min-width: 50%;
  width: 50% !important;
  min-height: 60vh;
  background-color: rgb(255, 236, 249);
  border-radius: 4px;
  overflow: hidden;
  // align-content: space-between;
  &.auto-size {
    min-width: inherit;
    min-height: inherit;
    width: auto!important;
    .modal-text, .modal-buttons{
      min-width: inherit;
      min-height: inherit;
    }
    
  }

  @media screen and (max-width: $bp_mobileLandscape) {
    font-size: 0.7em;
    .modal-text, .modal-buttons{
      font-size: 1.2em;
    }
  }
}


.modal-header {
  display: flex;
  // width: 100%;
  padding: 0.2em 0.6em;  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  font-size: 1.3em;
  font-weight: bold;
  background-color: $primary;
  color: white;
}

.modal-text {
  flex: 3;
  min-height: 7.5vh;
  // display: flex;
  padding: 1em 2em;
  // align-items: center;
  text-align: center;
}
.modal-buttons {
  flex: 1;
  min-height: 7.5vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 7px;
}
