@import  "../../../../../variables.scss";

.progress-tooltip-text{
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1.2em;
}
#progress{
    .progress-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        a, button{
            margin: 0.5em;            
        }
    }
    .course-score{
        display: flex;
        flex-direction: column;
        align-items: center;
        .score{
            font-size: 1.2em;
            span{
                color: rgb(1, 69, 172);
                font-weight: 800;
                font-size: 1.3em;
            }
        }

        .progress{
            font-size: 1.1em;
            font-weight: 600;
            span{
                color: green;
                font-weight: 800;
            }
        }
    }

    .progress-indicator{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.value0{
            svg{
                color: gray;
            }
        }
        &.value1{
            svg{
                color: $primary;
            }
        }
        &.value2{
            svg{
                color: green;
            }
        }
    }

    .data-table{
        margin: 1em 0;
    }

    .custom-header{
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        font-size: 0.9em;
        font-weight: bold;
        &.align-center{
            align-items: center;
        }
        &.align-left{
            align-items: flex-start;
        }
        &.align-right{
            align-items: flex-end;
        }

    }
}