@import "../../variables.scss";

#root {

    .courses-list{
        margin-bottom: 2em;
        
        @media print {
            font-size: 9px;       
            margin-top: 0;     

            .MuiContainer-root{
                margin: 0;
            }

            .MuiGrid-root.MuiGrid-container{
                display: flex;
                flex-direction: row!important;                
                justify-content: flex-start;
                flex-wrap: nowrap;
            }
        }
        .scrolling-margin{
            scroll-margin:3.5em;
        }
        .card-container{
            position: relative;
            
            .image-display{
                min-height: 10em;                
            }

            .stamp{                
                position: absolute;
                top: 0;
                right: 2em;
                z-index: 10;
                box-shadow: 2px 2px 4px rgba(0,0,0,0.3);
                display: flex;
                flex-direction: column;
                align-items: center;         
                justify-content: center;       
                border-radius: 50%;
                // transform: rotate(20deg);
                font-weight: 700;
                // text-shadow: 1px 1px 1px rgb(255,255,255);
                background-color: rgb(218, 218, 218);
                color: rgba(0,0,0,0.8);
                text-transform: uppercase;
                font-size: 0.8em;
                width: 6em;
                height: 6em;
                &.done{
                    background: #38f778;
                }
                &.failed{                    
                    background: #ff6767;
                    color: white;
                    text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
                }
                @media print {
                    top: 50%;
                    left: 3px;
                    transform: translateY(-50%);
                    width: 40px;
                    height: 40px;
                    font-size: 6px;   
                    padding: 0;   
                    box-shadow: none; 
                    border: 1px solid gray;    
                    opacity: 0.5;                      
                }
            }
    
            .course-card{
                background-color: rgb(217,217,217);
                border-radius: 0.6em;
    
                &.done {
                    background-color: #a5ffc3;                
                }
                &.failed{
                    background-color: #ffbfbf;
                }
                &.blocked{
                    opacity: 0.6;
                }

                span.text-label {
                    font-size: 1.2em;
                }
                
                span.text-value {
                    font-size: 1.3em;
                    font-weight: 700;
                    margin: 0 0.2em;
                    color: rgba(0,0,0,0.6);
                }

                @media print {
                    break-inside: avoid;
                    box-shadow: none;
                    border-bottom: 1px dotted gray;                   

                    h3{
                        font-size: 12px;  
                        padding: 0;                      
                    }
                }
            }

            @media print {
                margin: 0;
                padding: 0;
                

                .MuiGrid-item{
                    &:first-child{
                        flex-basis: 20%!important;
                        padding: 0;
                    }
                    &:last-child{
                        flex-basis: 20%!important;
                        padding: 0;
                    }
                    padding-left: 1em;
                    flex-basis: 60%!important;
                    max-width: inherit;
                }
            }



        }

    }

    ul.course-experiments-list{
        font-size: 0.9em;        
        
        

        @media screen and (max-width: $bp_tablet) {
            column-count: 1;
        }        

        @media screen and (min-width: $bp_tablet) {
            column-count: 1;
        }

        @media screen and (min-width: $bp_desktop) {
            column-count: 2;
        }
        
        @media print {
            // column-count: inherit!important;
            // display: flex;
            // flex-wrap: wrap;     
            // li {
            //     flex-basis: 30%;
            //     margin: 0.2em;
            //     padding-left: 0em;
            // }
        }

        li{
            line-height: 1em;
            margin-bottom: 0.3em;            
        }
    }
}