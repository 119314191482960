@import "../../variables.scss";

body #root .main {
  padding-bottom: 0px;
}

#root {
  .user-profile {
    //background-color: cadetblue;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column;
    //justify-content: center;
    margin-top: 1.6em;
    max-height: 55vh;

    .details-container {
      border-radius: $borderRadius;     
      background-color: $bgLight;   
      margin: 1em 0;
      width: 100%;

      .details-text{
        
        .details-row {
          margin: 0 0 0.4em;
          border-bottom: 2px dotted rgba(0,0,0,0.1);
          span:first-child {
            width: 48%;
            display: inline-flex;
            font-weight: 400;
            font-size: 1em;
            justify-content: flex-start;
            padding: 0.2em 0;
            margin-right: 2%;
            color: $primary;
          }
        } 
      }
    }

    .image-container {
      //background-color: wheat;
      display: flex;      
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .image {
        height: 20vh;
        width: auto;
        //border: 5px solid #636035;        
        border-radius: $borderRadius;
        box-shadow: 2px 2px 4px rgba(0,0,0,0.4);
      }
    }

    .status-credentials-container {
      border-radius: 8px;
      border: 2px solid black;
      align-content: flex-start;
      max-height: 35vh;
      padding: 1em;
      display: flex;

      .status {
        padding: 1em;
        border-bottom: 2px solid black;
        display: flex;
        flex-direction: row;
        max-height: 10vh;
        align-items: center;
        justify-content: space-around;
      }

      .credentials {
        padding: 1em;
        max-height: 28vh;

        display: flex;
        flex-direction: column;

        .list-text {
          margin-top: 25px;
        }
      }
    }

    .progress-container {
      border-radius: 8px;
      border: 2px solid black;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: center;

      flex-wrap: nowrap;
      padding: 1em;

      .accorion-item {
        width: 80%;
        margin-bottom: 0 !important;
        margin-top: 0 !important;

        #accordion-title {
          background-color: rgba(170, 177, 174, 0.432);
          display: flex;
        }
        #accordion-title > div {
          justify-content: space-between;
        }
      }

      .accorion-details {
        display: flex;
        flex-direction: column;

        .line {
          display: flex;
          flex-direction: row;

          justify-content: space-between;
          padding: 0 1em 0 1em;
        }
      }
    }

    .buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      a, button{        
        margin: 0 0 1em;
      }
    }
  }
}



ul.tooltip-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  list-style: none;
  padding: 0;
  font-size: 0.8em;
  li {
    list-style: none;
    padding: 0;
    margin: 0.2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    span:first-child {
      width: 5em;
      margin-right: 2em;
      white-space: nowrap;
    }
    span:nth-child(2),
    span:nth-child(3) {
      width: 4em;
      white-space: nowrap;
    }
  }
}
