.async-button{
  // this is the button content
  .MuiButton-label{

    display: flex;
    align-items: center;
    justify-content: stretch;
    
    .loading-icon{
      max-width: 1.2em;
      max-height: 1.2em;
      margin-right: 0.6em;
      color: rgba(255,255,255,0.7);
    }
  }

  &.icon-top .MuiButton-label{
    flex-direction: column;
    .loading-icon{
      margin-right: 0;
      margin-bottom: 0.3em;
    }
  }
  &.icon-bottom .MuiButton-label{
    flex-direction: column-reverse;
    .loading-icon{
      margin-right: 0;
      margin-top: 0.3em;
    }
  }
  &.icon-left .MuiButton-label{
    flex-direction: row;
  }
  &.icon-right .MuiButton-label{
    flex-direction: row-reverse;
    .loading-icon{
      margin-right: 0;
      margin-left: 0.6em;
    }
  }
}