$PI: 3.14159;

$headerHeight: 40px;
$mainTopMargin: 36px;

$bp_mobile: 320px;
$bp_mobileLandscape: 480px;
$bp_tablet: 768px;
$bp_tabletLandscape: 1024px;
$bp_desktop: 1200px;
$bp_desktopLarge: 1500px;
$bp_desktopWide: 1920px;

//$headerHeight: 40px;
$footerHeight: 40px;
$sidebarWidth: 240px;
$headerLogoOffset: 10px;

//$mainTopMargin: 20px;

$pagePaddingX: 2em;

$bgLight: #f0f0ec;


$questionColor: #5472fc; // #dd55ff;

$primary: #00479b;
$primaryLight: #4f72cd;
$primaryLighter: #90a3d1;
$primaryDark: #00216c;

$linkColor: #4c6be1;
$linkColorVisited: #3556da;

$logoColor: #ff0048;
$logoColor50p: #ff004881;

$borderRadius: 12px;

body{
  --bg-light: #{$bgLight};
}


