$rightColor: green;

.quiz-container {
  // background-color: #cfe8fc;
  // background-color: #eff7fd;
  background-color: #e5f4ff;
  padding: 2em;
  border-radius: 6px;
  align-items: stretch;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;

  .quiz-title {
    font-size: 1.3em;
    font-weight: 400;
    color: #3f51b5;
    margin-bottom: 1em;
    text-transform: capitalize;
    text-align: center;
  }
  
  .question-container {
    padding: 1em 0;

    margin-bottom: 1em;
    border-bottom: 3px dotted rgba(0,0,0,0.1);

    &:last-child{
      border-bottom: none;
      margin-bottom: 0;
    }

    fieldset {
      width: 100%; // extend the fieldset to the full width of the container
    }

    .question-image-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0.4em 0 1em;
      img {
        max-width: 100%;
        max-height: 60vh;
        border-radius: 0.3em;
        box-shadow: 2px 2px 4px rgba(0,0,0,0.5);
      }
    }

    .answered-correctly{
      .question-text{
        color: rgba(0,0,0,0.5);
      }
  
      // water down all other options in correctly answered question
      svg{
       color: rgba(0,0,0,0.1);
      }
      .selected{
        // mark selected correct answer with color
        svg{
          color: $rightColor;
          ;
        }
        .question-text{
          color: $rightColor;
          font-weight: 700;
        }
      }
    }

    // answer label  
    .prepared-answer-content{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      img{
        max-width: 100%;
        max-height: 40vh;
        border-radius: 0.3em;
        box-shadow: 2px 2px 4px rgba(0,0,0,0.5);
      }
    }

    .question-title {
      font-weight: bold;
      color: #f50057;
      margin-bottom: 12px;
      line-height: 1.2em;
      font-size: 1.2em;
      font-weight: 400;
    }
  }


  
  .question-text {
    flex: 1;
    padding: 10px;
  }
  
  

  

  
  
  .image {
    // width: 200px;
    // height: auto;
    // padding: 15px;
  
    // .question-image {
    //   width: 300px;
    // }
  }
  
  .option {
    display: flex;
    flex-direction: row;
  }

  .submit-quiz-button{
    align-self: center;
  }
}


.custom-radio-item{
  
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border-radius: 0.5em;
    transition: 0.3s all;
    
    &:hover{
      background-color: rgba(0,0,0,0.1);
    }
  
}