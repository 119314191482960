@import "../../../variables.scss";

.language-switch {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  // color: $logoColor;
  color: $primary;
  // svg{
  // color: $primary;
  // }
  margin-right: 0.2em;
}

@media print {
  .language-switch {
    display: none;
  }
}
