.course-preface {
  .placeholder-text {
    background-color: rgb(233, 233, 233);
    border: 1px solid rgb(221, 221, 221);
    font-size: 1.2em;
    color: rgb(202, 201, 201);
    border-radius: 10px;
    padding: 2em;
    margin: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-wrapper {
    margin: 1em 0;
  }
}
