$modalPadding: 1em;

.password-modal-container {
  flex-direction: column;
  display: flex;
  position: absolute;
  padding: $modalPadding;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 30%;
  width: calc(400px - #{$modalPadding});
  max-width: calc(90% - #{$modalPadding});
  // min-height: 50vh;
  height: calc(300px - #{$modalPadding});
  max-height: calc(90% - #{$modalPadding});    
  background-color: var(--bg-light, white);
  border-radius: 8px;
  overflow: hidden;
  justify-content: space-around;

  

  .header {
    font-weight: bold;
    font-size: 1.3em;    
    text-align: center;
  }

  .prompt{
    font-size: 1em;
    font-weight: normal;
    color: rgba(0,0,0,0.8);
    text-align: center;
  }
  

  .fields {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    > div{
      margin: 0 0 1em;
    }
  }

  .buttons-container {
    // padding: 10px;
    display: flex;
    justify-content: space-between;
    button{
      margin: 0.2em;
    }
  }
}