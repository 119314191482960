@import "../../variables.scss";

#root {
  header {
    .top-right {
      display: flex;
      align-items: center;
      .user-icon {
        position: relative;
      }
    }

    .top-left {
      a {
        text-decoration: none;
        color: $logoColor;
        display: flex;
        align-items: center;
        span {
          margin-left: 0.2em;
        }
      }

      img {
        max-height: $headerHeight - $headerLogoOffset;
      }
    }
  }

  .notifications-container {
    // override the absolute position in original styles
    // which makes the notifications hang in the middle of the page
    position: fixed;
  }
}
