@import './variables.scss';

html,
body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body {
  flex-grow: 1;

  #root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;

    
    h1, h2, h3, h4, h5, h6{
      font-family: 'Noto Sans', sans-serif;
      font-weight: 400;
    }

    h1, h2, h3 {
      font-weight: 300;
    }

    h1 {
      font-size: 3em;
      margin: 0.6em 0 0.2em;
    }
    h2{
      font-size: 2.2em;
      margin: 0.6em 0 0.1em;
    }
    h3{
      font-size: 1.9em;
      margin: 0.6em 0 0.1em;
      
    }

    .navbar {
      // background: #5c7372;
      height: $headerHeight;
      padding: 0 2em;
      color: white;
      .menu-button {
        cursor: pointer;
      }
    }

    .main {
      flex-grow: 1;
      display: flex;
      justify-content: stretch;
      transition: 255ms padding-left;
      padding-top: #{$headerHeight};
      // padding-bottom: #{$footerHeight + $mainTopMargin};
      &.drawer-open {
        padding-left: $sidebarWidth;
      }
    }

    .footer {
      display: flex;
      flex-grow: 0;
      height: $footerHeight;
      align-items: center;
      width: 100%;
      justify-content: center;
      color: white;
      z-index: 1;
      // background: #7ea09f;
    }

    .breadcrumbs{
      padding: 10px;
      margin: 0 0 10px 10px;
    } 


    

    .auto-breadcrumbs {
      a{
        color: $linkColor;        
        &:visited{
          color: $linkColorVisited;
        }
      }
    }

    .footer {
      display: flex;
      flex-grow: 0;
      height: 70px;
      align-items: center;
      width: 100%;
      justify-content: center;
      background: #7ea09f;
    }

    @media print {
      .auto-breadcrumbs, button, .center-logo {
          display: none;
        }        
  
      h1{
        font-size: 12px;
      }
      
    }
  }
  .skeleton {
    padding: 1em;
    display: flex;
    flex-grow: 1;
    min-height: 3em;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: $borderRadius;
    margin: 1em 0;
  }


  
}


$flexItemsMethods: baseline, flex-start, flex-end, stretch, center;
$flexContentMethods: baseline, flex-start, flex-end, stretch, center, space-around, space-evenly, space-between;

.flex {
  display: flex !important;

  &.column{
    flex-direction: column;
  }
  &.row{
    flex-direction: row;
  }

  @each $var in $flexItemsMethods {
    &.align-items-#{$var}{
      align-items: $var;
    }  
  }

  @each $var in $flexContentMethods  {
    &.justify-content-#{$var}{
      justify-content: $var;
    }
  }
}

.grow-0{
  flex-grow: 0;
}

.grow-1{
  flex-grow: 1;
}

.grow-2{
  flex-grow: 1;
}


// Redefine scrollbars
::-webkit-scrollbar {
  background: whitesmoke;
  width:  8px;
}

::-webkit-scrollbar-thumb{
    width: 16px;
    background: #75757548;      
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.5)
}